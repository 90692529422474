import React, { useContext } from 'react'
import {signOut} from "firebase/auth"
import { auth } from '../firebase'
import { AuthContext } from '../context/AuthContext'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

  import { faSearch } from '@fortawesome/free-solid-svg-icons'
  import { faBell } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
  const {currentUser} = useContext(AuthContext);

   const baseURL = 'https://drinkdateditch.com/backend/storage/';


  return (

         
      <header className="header header-fixed border-0">
        <div className="header-content">
        <div className="container">
          <div className="left-content">
            <a href="https://web.drinkdateditch.com/filter.php">
               <FontAwesomeIcon icon={faSearch} />
            </a>
          </div>
          <div className="mid-content header-logo">
            <a href="https://web.drinkdateditch.com/home.php">
              <img src="https://web.drinkdateditch.com/assets/images/logo.png" alt=""/>
            </a>
          </div>
          <div className="right-content dz-meta">
            <a href="https://web.drinkdateditch.com/notification.php" className="filter-icon">
                <FontAwesomeIcon icon={faBell} />
            </a>
          </div>
        </div>
          
        </div>
    </header>
    
  )
}

export default Navbar