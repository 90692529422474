import React, { useContext, useEffect, useRef ,useState} from "react";
import moment from "moment";
const isJustNow = (time) => Date.now() - new Date(time) < 5000;
const isToday = (time) => Date.now() - new Date(time) < 24 * 60 * 60 * 1000;
const Timer = ({ time: currentTime }) => {
  const [time, setTime] = useState(currentTime);

  let toDisplay = moment(time).format("DD MMM HH:mm A");
  if (isJustNow(time)) {
    toDisplay = "Just now";
  } else if (isToday(time))
    toDisplay = moment(time).format("HH:mm A");
  return <small>{toDisplay}</small>;
};

export default Timer;
