import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const Example = (props) => {
  return (
    <>
   
      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>Looks Like your msg sending limit is exausted.Please Upgrade your membership plan to continue messaging.</Modal.Body>
        <Modal.Footer>
          <a className="btn btn-default" href="https://web.drinkdateditch.com/profile.php" >
            Back to Home
          </a>
          <a className="btn btn-danger" href="https://web.drinkdateditch.com/membership.php">
            Upgrade
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};