import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import Timer from "./Timer";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';


const Message = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const ref = useRef();
 const baseURL = 'https://drinkdateditch.com/backend/storage/';
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`message ${message.senderUser.userIdentity === currentUser.email && "owner"}`}
    >

      <div className="messageInfo">
       {(message.senderUser.userIdentity != currentUser.email) ? 
          <img src={baseURL + data.user.image } alt="" /> : ''
       }
      </div>
      <div className="messageContent">
        
        {message.msgType=='image'?
             <PhotoProvider>
      <PhotoView src={baseURL + message.image}>
        <img src={baseURL + message.image} alt="" />
      </PhotoView>
    </PhotoProvider>
            :''
        }


      



       {message.msg?<p>{message.msg}</p>:null} 
         <Timer key={message.time} time={message.time} />
      </div>
    </div>
  );
};

export default Message;
