import {
  createContext,
  useContext,
  useState,
  useReducer,
} from "react";
import { AuthContext } from "./AuthContext";
//import { ChatUserContext } from "./ChatUserContext";

export const ChatContext = createContext();



export const ChatContextProvider = ({ children }) => {
  const { currentUser , chatUser} = useContext(AuthContext);
  //const { chatUser} = useContext(ChatUserContext);
  const [showChats, setShowResults] = useState(false);
  //console.log("chatUser");
 // console.log(chatUser);
  const INITIAL_STATE = {
    chatId: "null",
    user: {},
  };

  const chatReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action.payload,
          chatId: action.chatId
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  return (
    <ChatContext.Provider value={{ data:state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
