import React from "react";
const Footer = () => {
  return (
    
    <div className="menubar-area footer-fixed">
    <div className="toolbar-inner menubar-nav">
      <a href="https://web.drinkdateditch.com/home.php" className="nav-link ">
        <i><img src="https://web.drinkdateditch.com/assets/icons/explore.png" alt=""/></i>
        Explore
      </a>
      <a href="https://web.drinkdateditch.com/live.php" className="nav-link">
        <i><img src="https://web.drinkdateditch.com/assets/icons/join_lives.png" alt=""/></i>
        Live
      </a>
      <a href="https://web.drinkdateditch.com/chat-list.php" className="nav-link active">
        <i><img src="https://web.drinkdateditch.com/assets/icons/message_ac.png" alt=""/></i>
        Message
      </a>
      <a href="https://web.drinkdateditch.com/profile.php" className="nav-link">
        <i><img src="https://web.drinkdateditch.com/assets/icons/profile.png" alt=""/></i>
        Profile
      </a>
    </div>
  </div>
  );
};

export default Footer;




