import { doc, onSnapshot , collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";
import Message from "./Message";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const { data } = useContext(ChatContext);


  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
     setMessages(messages => []);
     const unsub = onSnapshot(collection(db, "chat", data.chatId,'chat'), (querySnapshot) => {
        
         querySnapshot.forEach((doc) => {

          const data = doc.data();
          setMessages(messages => [...messages, data]);
        });
      });



    return () => {
      unSub();
    };
  }, [data.chatId]);

 

  return (
    <div className="messages">
      {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))}
    </div>
  );
};

export default Messages;
