import { doc, onSnapshot ,collection ,setDoc} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";

const Chats = () => {
  const [chats, setChats] = useState([]);
  const baseURL = 'https://drinkdateditch.com/backend/storage/';
  const { currentUser , chatUser} = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  useEffect(() => {
    const getChats = () => {

    if(chatUser && chatUser.email != null)
    {
         const test = onSnapshot(doc(db, "userchatlist", currentUser.email,'userlist',chatUser.email), (rec) => {
          if(rec.exists()){
            handleSelect(rec.data().user,rec.data().conversationId);
          }
          else{
            const user = {
                age:String(chatUser.site_info.age),
                userIdentity:chatUser.email,
                city:chatUser.site_info.live,
                date: Date.now(),
                image:chatUser.site_info.image.image,
                isHost:false,
                isNewMsg:false,
                userid:chatUser.site_info.id,
                username:chatUser.site_info.fullname
              };

            const chat_list = {
              block : false,
              blockFromOther : false,
              conversationId : currentUser.email+chatUser.email,
              deletedId : false,
              isDeleted : false,
              isMute : false,
              lastMsg : "",
              newMsg : "",
              time :  Date.now(),
              user : user
              };

              const doc_id =  String(chatUser.email);
              const subColRef = doc(db, "userchatlist", currentUser.email,'userlist', doc_id);
              setDoc(subColRef, chat_list);

              // second user

               const user2 = {
                age:String(currentUser.site_info.age),
                userIdentity:currentUser.email,
                city:currentUser.site_info.live,
                date: Date.now(),
                image:currentUser.site_info.image.image,
                isHost:false,
                isNewMsg:false,
                userid:currentUser.site_info.id,
                username:currentUser.site_info.fullname
              };

            const chat_list2 = {
              block : false,
              blockFromOther : false,
              conversationId : currentUser.email+chatUser.email,
              deletedId : false,
              isDeleted : false,
              isMute : false,
              lastMsg : "",
              newMsg : "",
              time :  Date.now(),
              user : user2
              };

              const doc_id2 =  String(currentUser.email);
              const subColRef2 = doc(db, "userchatlist", chatUser.email,'userlist', doc_id2);
              setDoc(subColRef2, chat_list2);

          }

        });
    }
    


     const unsub = onSnapshot(collection(db, "userchatlist", currentUser.email,'userlist'), (querySnapshot) => {
         setChats(chats => []);
         querySnapshot.forEach((doc) => {

          const data = doc.data();
          setChats(chats => [...chats, data]);
        });
      });

      return () => {
        unsub();
      };
    };

    currentUser.email && getChats();
  }, [currentUser.email]);

  const handleSelect = (u,c) => {
    dispatch({ type: "CHANGE_USER", payload: u ,chatId : c});
    
  };

  return (
    <div className="chats">

      {Object.entries(chats)?.sort((a,b)=>b[1].date - a[1].date).map((chat) => (
        <div
          className="userChat"
          key={chat[0]}
          onClick={() => handleSelect(chat[1].user,chat[1].conversationId)}
        >
        {console.log(chat[1])}
          <img src={baseURL + chat[1].user.image} alt="" />
          <div className="userChatInfo">
            <span>{chat[1].user.username}</span>
            <p>{chat[1].lastMsg}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Chats;
