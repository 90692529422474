import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
export const AuthContext = createContext();



function useQueryParams() {
    const params = new URLSearchParams(
      window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}




export const AuthContextProvider = ({ children }) => {


  const { email, chat_email, key, count, ...unknown } = useQueryParams();
  const [currentUser, setCurrentUser] = useState({});
  const [chatUser, setChatUser] = useState({});
  const [unsub, setuser] = useState({});
  const [unsub2, setuser2] = useState({});

  useEffect(() => {

     const dec_key = window.atob(key);
       const val_keys = dec_key.split('||');

    if(email != "" && val_keys[0] == email)
    {

        const user = {};
        const formData = new FormData();
        formData.append("email", email);
        // make a POST request to the File Upload API with the FormData object and Rapid API headers
        axios
          .post("https://drinkdateditch.com/backend/api/getUserDetails", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "apikey": "123",
            },
          })
          .then((response) => {
             user.site_info = response.data.data;
             user.email = response.data.data.identity;
             user.photoURL = response.data.data.image.image;
             user.displayName = response.data.data.fullname;
             setCurrentUser(user);
            const unsub = setuser(user);

          })
          .catch((error) => {
            console.log(error);
             setCurrentUser(user);
          });


        const chatUser = {};
        const formData2 = new FormData();
        formData2.append("email", chat_email);
        // make a POST request to the File Upload API with the FormData object and Rapid API headers
        axios
          .post("https://drinkdateditch.com/backend/api/getUserDetails", formData2, {
            headers: {
              "Content-Type": "multipart/form-data",
              "apikey": "123",
            },
          })
          .then((response) => {
             chatUser.site_info = response.data.data;
             chatUser.email = response.data.data.identity;
             chatUser.photoURL = response.data.data.image.image;
             chatUser.displayName = response.data.data.fullname;
             setChatUser(chatUser);
              const unsub2 = setuser2(chatUser);
             //console.log(chatUser);
          })
          .catch((error) => {
            console.log(error);
             setCurrentUser(user);
          });



    }
    else{

         const unsub = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
         });
    }

  }, []);

  return (
    <AuthContext.Provider value={{ currentUser , chatUser }}>
      {children}
    </AuthContext.Provider>
  );
};
