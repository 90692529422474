import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {

  apiKey: "AIzaSyCZMXBAZsXxtG-S9KjNflE3UH-0WSVc5d0",

  authDomain: "dddd-42e7d.firebaseapp.com",

  databaseURL: "https://dddd-42e7d-default-rtdb.firebaseio.com",

  projectId: "dddd-42e7d",

  storageBucket: "dddd-42e7d.appspot.com",

  messagingSenderId: "50658256184",

  appId: "1:50658256184:web:37a4f5d5df902128294dcd",

  measurementId: "G-Q2SMJPX3E4"

};



// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
