import React, { useContext } from "react";
import Navbar from "./Navbar"
import Search from "./Search"
import Chats from "./Chats"
import Footer from "./Footer"
import { ChatContext } from "../context/ChatContext";
const Sidebar = () => {
    const { data } = useContext(ChatContext);
  return (
    <div className="sidebar 123" style={data.chatId == "null" ?{}:{ display: 'none' }}>
      <Navbar />
      {/*<Search/>*/}
      <Chats/>
      <Footer/>
    </div>
  );
};

export default Sidebar;
