import React, { useContext, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Img from "../img/img.png";
import Attach from "../img/attach.png";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { Example } from "./Modal.jsx";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  addDoc,
  setDoc,
  collection
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import axios from "axios";

const Input = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [msgAllowed, setMsgAllowed] = useState(true);

  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const [modalShow, setModalShow] = React.useState(false);

  const handleSend = async () => {

    const formData = new FormData();
    formData.append("user_id", currentUser.site_info.id);

    axios
      .post("https://drinkdateditch.com/backend/api/check_msg_limit", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "apikey": "123",
        },
      })
      .then((response) => {
          if(response.data.status == false)
          {
            setModalShow(true);
            setMsgAllowed(false);
            return false;
          }
          else{
            sendmsg();
          }
      });

    }

  const sendmsg = async () => {


      if(msgAllowed == false)
      {
        return false;
      }

    if(img) {

          // get the selected file from the input
    const file = img;
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post("https://drinkdateditch.com/backend/api/storeFileGivePath", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "apikey": "123",
        },
      })
      .then((response) => {
    // handle the response
        console.log(response.data);

         const user = {
        age:String(currentUser.site_info.age),
        userIdentity:currentUser.email,
        city:currentUser.site_info.live,
        date: Date.now(),
        image:currentUser.site_info.image.image,
        isHost:false,
        isNewMsg:false,
        userid:currentUser.site_info.id,
        username:currentUser.site_info.fullname
      };
     
      const msg = {
          id: String(data.user.userid),
          image: response.data.path ,
          msg:text,
          msgType:"image",
          not_deleted_identities: [currentUser.email,data.user.userIdentity],
          senderUser:user,
          time: Date.now(),
          video:null
        };

      const doc_id =  String(msg.time);
      const subColRef = doc(db, 'chat',  data.chatId ,'chat', doc_id);
       setDoc(subColRef, msg);


      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });



    } else {
     
      const user = {
        age:String(currentUser.site_info.age),
        userIdentity:currentUser.email,
        city:currentUser.site_info.live,
        date: Date.now(),
        image:currentUser.site_info.image.image,
        isHost:false,
        isNewMsg:false,
        userid:currentUser.site_info.id,
        username:currentUser.site_info.fullname
      };
     
      const msg = {
          id: String(data.user.userid),
          image:null,
          msg:text,
          msgType:"msg",
          not_deleted_identities: [currentUser.email,data.user.userIdentity],
          senderUser:user,
          time: Date.now(),
          video:null
        };

         const doc_id =  String(msg.time);
      const subColRef = doc(db, 'chat',  data.chatId ,'chat', doc_id);
      await setDoc(subColRef, msg);
     
    }


    const doc_id =  Date.now();
    const fl = parseFloat(doc_id);
    await updateDoc(doc(db, "userchatlist",  currentUser.email ,'userlist' , data.user.userIdentity), 
      { lastMsg:text,
        time: fl
      });

     await updateDoc(doc(db, "userchatlist", data.user.userIdentity ,'userlist' , currentUser.email), 
      { lastMsg:text,
        time: fl
      });


    const formData3 = new FormData();
    formData3.append("user_id", currentUser.site_info.id);

    axios
      .post("https://drinkdateditch.com/backend/api/use_msg_limit", formData3, {
        headers: {
          "Content-Type": "multipart/form-data",
          "apikey": "123",
        },
      })
      .then((response) => {
          
      });

    setText("");
    setImg(null);
  };
  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <div className="send">
        
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file">
          <img src={Img} alt="" />
        </label>
        <button onClick={handleSend}>Send</button>
      </div>

      <Example show={modalShow}  onHide={() => setModalShow(false)} >
      </Example>
    </div>
  );
};

export default Input;
