import React, { useContext , useState} from "react";
import Cam from "../img/cam.png";
import Back from "../img/back.png";
import Add from "../img/add.png";
import More from "../img/more.png";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../context/ChatContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


     const baseURL = 'https://drinkdateditch.com/backend/storage/';

const Chat = () => {
  const { data } = useContext(ChatContext);
    const { dispatch } = useContext(ChatContext);
   const [dropOpen, setDropOpen] = useState(false);

      const handleSelect = (u,c) => {
    dispatch({ type: "CHANGE_USER", payload: u ,chatId : c});
    
  };

  const ShowDrop = (dropOpen)=>{
    if(dropOpen == true)
      setDropOpen(false);
    else
      setDropOpen(true);
  }

  return (
    <div className="chat"  style={data.chatId !="null" ? {} : { display: 'none' }}>
      <header className="header header-fixed bg-white">
      <div className="container">
        <div className="header-content">
          <div className="left-content me-3">
            <a href="javascript:void(0);" className="back-btn"  onClick={() => handleSelect([],"null")}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </a>
          </div>
          <div className="mid-content d-flex align-items-center text-start">
            <a href="javascript:void(0);" className="media media-40 rounded-circle me-3">
              <img src={baseURL + data.user?.image} alt="/"/>
            </a>
            <div>
              <h6 className="title">{data.user?.username},24</h6>
            </div>
          </div>
          <div className="right-content d-flex align-items-center dropdown">
            <a href="javascript:void(0);" className="dz-icon btn rounded-1 m-0 light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"  onClick={() => ShowDrop(dropOpen)} >
              <i><img src="https://web.drinkdateditch.com/assets/icons/more_horizontal.png" alt=""/></i>
            </a>
            {dropOpen===true?
            <ul className="dropdown-menu" >
              <li><a className="dropdown-item" href="#">Block</a></li>
              <li><a className="dropdown-item" href="#">Report</a></li>
              </ul>
              :''}
          </div>
                </div>
      </div>
    </header>

      <Messages />
      <Input/>
    </div>
  );




};

export default Chat;
